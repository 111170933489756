import React from 'react';
import PropTypes from 'prop-types';

import s from './headline.module.scss';

function Headline(props) {
  const {
    h1,
    h3,
    h2,
    html,
    el,
    primary,
    secondary,
    tertiary,
    white,
    center,
    ...other
  } = props;
  const primaryClasses = primary ? s.primary : '';
  const tertiaryClasses = tertiary ? s.tertiary : '';
  const secondaryClasses = secondary ? s.secondary : '';
  const whiteClasses = white ? s.white : '';
  const colorClasses = primaryClasses || tertiaryClasses || secondaryClasses || whiteClasses || '';
  const centerClass = center ? s.center : '';
  const positionClasses = `${centerClass}`;
  const getElement = () => {
    if (h1) return 'h1';
    if (!h2 && !h3) return 'h1';
    return h2 ? 'h2' : 'h3';
  };
  const elementTag = el || getElement();
  const defaultClasses = `${
    s[getElement()]
  } ${colorClasses} ${positionClasses}`;
  const headlineInfo = {
    el: elementTag,
    className: `${defaultClasses} ${props.className || ''}`
  };
  const headlineProps = {
    ...other,
    className: headlineInfo.className
  };
  const Tag = headlineInfo.el;
  if (html) {
    return (
      <Tag dangerouslySetInnerHTML={{ __html: `${html}` }} {...headlineProps} />
    );
  }
  return <Tag {...headlineProps}>{props.children}</Tag>;
}

const validateProp = (props, name, component) => {
  if (!props.h1 && !props.h2 && !props.h3) {
    return new Error(
      `Please specify either h1, h2 or h3 prop in ${component}'.`
    );
  }
  if (props[name] && typeof props[name] !== 'boolean') {
    return new Error(`Prop ${name} in ${component} must be a boolean value.`);
  }
  return null;
};

Headline.propTypes = {
  h1: validateProp,
  h2: validateProp,
  h3: validateProp,
  el: PropTypes.string
};

export default Headline;
