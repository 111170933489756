import React from 'react';
import Img from 'img';
import Container from 'container';
import Headline from 'shared/headline';
import Button from 'button';
import s from './notfound.module.scss';

// Main Component
export default ({ headline, paragraph, button, image, image_title, image_button }) => (
  <Container className={s.section}>
    <div className={s.flexed}>
      <div className={`${s.block} ${s.wide}`}>
        <Headline>{headline}</Headline>
        <p>{paragraph}</p>
        {button && <Button href={button.href}>{button.text}</Button>}
      </div>
      <div className={s.block}>
        <div className={s.imgWrapper}>
          {image && <Img src={image.title} alt={image.title} />}
          <div className={s.content}>
            <h4>{image_title}</h4>
            {image_button && <Button href={image_button.href}>{image_button.text}</Button>}
          </div>
        </div>
      </div>
    </div>
  </Container>
);
