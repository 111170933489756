import React from 'react';
import SimpleTitle from 'components/templates/simple_title';
import NotFound from 'components/templates/page_not_found';

export default function NotFoundPage() {
  return (
    <main>
      <SimpleTitle {...data.simpleTitle} />
      <NotFound />
    </main>
  );
}

const data = {
  simpleTitle: {
    title: 'Sorry, page not found!',
    subtitle: '404: Page Error',
  },
};
